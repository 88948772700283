import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';

const SalesforceDevelopmentServicesPage = () => {
    const [services] = React.useState([
        {
          id: 1,
          title: 'Salesforce Development Experience',
          desc: '10+ years of experience with successful installation across multiple segments.',
          image: 'marketing-automation-services-india-usa.png',
          alt:'Salesforce marketing automation services USA India',
          imagetitle:'Experience ',
          url: {
           
          }
        },
        {
          id: 2,
          title: 'Certified Salesforce Developers',
          desc: 'Team with 115+ Certified Salesforce professionals. ',
          image: 'salesforce-marketing-automation-certified-consultants.png',
          alt:'marketing automation Salesforce certified consultants India USA',
          imagetitle:'Salesforce Certifications',
          url: {
          }
        },
        {
          id: 3,
          title: 'End-to-End Support',
          desc: 'One stop shop for the entire spectrum of Salesforce consulting, including strategy, implementation, development and support. ',
          image: 'marketing-automation-agencies-india-usa.png',
          alt:'marketing automation agencies India',
          imagetitle:'Salesforce Support',
          url: {
          }
        },
        {
          id: 4,
          title: 'Industry Expertise',
          desc: 'We understand industry specific challenges and infuse strategic guidance & best practices in our solutions. ',
          image: 'marketing-automation-companies.png',
          alt:'marketing-automation-companies',
          imagetitle:'Industry Experience',
          url: {
          }
        },
     
      ]);
  return (
        <Layout>
           <Helmet>
        <title>Offshore Salesforce Development Services Company | Hire SFDC Developers</title>
        <meta name="description" content="Cymetrix's offshore Salesforce development services in India deliver custom solutions, from Apex coding to Lightning components. Hire certified Salesforce developers to meet your unique business needs."/>
      <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/salesforce-development-services/" />
      </Helmet>
            <div className="salesforce-development-consulting-background flex flex-col w-full">
                <p className="block text-base xl:text-5xl xl:px-48 xl:mt-48 md:mt-16 sm:mt-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                Get most out of your Salesforce ecosystem with Cymetrix’s Salesforce Development Services
                </p>
               
            </div>
            <div className="relative max-w-6xl mx-auto" style={{backgroundColor: '#f6f6f6'}}>
                <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 justify-center">
                    <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                        <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                            <img
                                className="h-64 w-64"
                                src={require('./../../images/services/saleforceApiImg.png')}
                                alt="Hero"
                                />
                        </div>
                    </div>
                    <div className="sma:mx-3 relative lg:w-8/12 flex-shrink-0 lg:text-lef mx-auto justify-center">
                        <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                        Salesforce Development Services
                        </h1><br />
                        <hr className="border-solid border-black mb-1" style={{borderWidth: 0.1}}/>
                        <p className="font-hairline">Unleash the full potential of your Salesforce environment with a trusted Salesforce development company. Whether you’re looking to build custom applications, streamline your business processes, or enhance user experience, our expert team is here to transform your vision into reality. With a deep understanding of Salesforce’s ecosystem and a commitment to innovation, we create solutions that not only meet your current needs but also scale with your business as it grows.
                        </p>
                        <p className="font-hairline">At Cymetrix, we don’t just develop—we partner with you to drive success. Our Salesforce development services are designed to seamlessly integrate with your existing operations, ensuring you get the most out of every feature Salesforce has to offer. From custom app development to advanced AI integration, we empower your organization to thrive in a competitive landscape, making every interaction with your customers more meaningful and effective. Let’s work together to build a Salesforce experience that’s as unique as your business.
                        </p>



                        <div className="bg-transparent hover:border-blue-500 text-gray-700 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center">
                            <Link target="_blank"
                                to="/contact-us">Connect with Us</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="product-background w-full flex flex-col items-center" title="Background image of Salesforce developement service" alt="
Salesforce Development Service Background  | Cymetrix Software
">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Salesforce Development Services</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Custom Salesforce Application Development</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Cymetrix’s Salesforce developers can help you craft custom applications perfectly tailored to your organizational needs using Salesforce tools like Apex, Visualforce, and Lightning Components.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce Lightning Development</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Transform your user experience with dynamic, modern interfaces and apps built on the Salesforce Lightning Component Framework and Lightning Web Components (LWC).</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Apex Development and Automation</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Supercharge your processes with custom business logic using Apex, automating workflows and extending Salesforce’s capabilities with our expert Salesforce developers</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Visualforce Page Development</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">With our Salesforce development services, we can help you create visually stunning, custom Visualforce pages to elevate the user experience, whether in Salesforce Classic or Lightning apps.</div>
                            </div>
                        </div>


                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Integration</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Experience a seamless integration of your Salesforce platform with external systems and apps, including marketing tools, accounting software, and e-commerce platforms, using REST/SOAP APIs and middleware.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce AppExchange Development</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Expand your reach by developing and publishing apps on Salesforce AppExchange for broader distribution. While our team will ensure they meet Salesforce’s security and performance standards.</div>
                            </div>
                        </div>


                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Customization Services</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Get most out of Salesforce and tailor as per your business need by creating custom objects, fields, layouts, workflows, and validation rules that align with your processes.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Data Migration</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Move to Salesforce and get started smoothly and effortlessly. Our Salesforce developers will help you migrate your data safely from legacy systems to Salesforce, ensuring accuracy, integrity, and consistency throughout the transition.</div>
                            </div>
                        </div>


                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Mobile App Development</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Bring your Salesforce experience on the go with custom mobile apps developed using Salesforce Mobile SDK or Salesforce1.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Lightning Migration</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Transition to Lightning Experience smoothly and effortlessly. With Cymetrix, you will be assured of a seamless migration from Salesforce Classic to Lightning, with redesigned customizations and user training for a seamless switch.</div>
                            </div>
                        </div>


                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Support and Maintenance</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Keep your Salesforce environment running at its best with our ongoing support, troubleshooting, and enhancements.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Security and Compliance Solutions</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">We understand the significance of keeping your data secure. Hence, we will make sure to implement security best practices, data governance, and compliance solutions to protect sensitive information within Salesforce.</div>
                            </div>
                        </div>


                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Consulting</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Get expert guidance on Salesforce development strategies, best practices, and solution architecture to maximize your return on investment on Salesforce platform.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Communities Development</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Our team of expert’s developers can help you build and custom Salesforce communities (Experience Cloud) to enhance collaboration among customers, partners, and employees based on their needs and requirements.</div>
                            </div>
                        </div>


                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Salesforce Einstein AI and Analytics Integration</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Harness the power of Salesforce Einstein for smarter decision-making. We can help you implement and integrate Salesforce Einstein AI for advanced analytics, predictive insights, and automation within your Salesforce ecosystem.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">Custom Workflow and Process Automation</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Our Salesforce development experts at Cymetrix can help you streamline operations with custom workflows and automations using Salesforce Flow, Process Builder, and Apex.</div>
                            </div>
                        </div>



                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Request Consultation</Link>
                        </div>
                    </div>

                    
                </div>
            </div>


            <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">

          <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-consultants-india-mumbai.png')}
                      alt="Hire Salesforce certified consultants india mumbai"
                      title="Niche Tech On-Demand Talent Services"
                      />
                </div>
              </div>

              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Power Your Growth Journey with On-Demand Certified Salesforce Talent 														
              </h1><br />
              <p className="font-hairline text-justify">Whether you need to rapidly expand your operations or tackle complex projects, our on-demand model ensures that the right Salesforce developers and other certified consultants are available when you need them. 
              </p><br/>
              <p className="font-hairline text-justify"><Link to="/services/staff-augmentation-services" class="underline text-blue-700">Tech Staff Augmentation Services</Link>
              </p><br/>
              </div>
              

          </div>
        </div>

        <div className="service-background w-full flex flex-col items-center ">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Why Choose Cymetrix for Salesforce Development Services" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={service.imagetitle} alt={service.alt} className="w-24 h-24"/>
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              <Link to={service.url.url} className="mt-5 text-hover text-lg text-center">{service.url.title}</Link>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>
        
        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">Case Studies</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-consulting-services-usa-pharma.png')} alt="Salesforce consulting partners pharma case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/b2b-commerce-cloud-pharma/" >Streamlining global wholesale operations with Salesforce & SAP for a pharmaceutical company</Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partner-india-fintech.png')} alt="Salesforce consulting fintech company | Cymetrix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/fintech-community-cloud/">Revolutionizing bill discounting with a self-serving co-lending fintech platform</Link></h1>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partners-license-types.png')} alt="Salesforce development services | Cymterix Software" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Salesforce License Types</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements for cost optimization.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce offshore development companies India" title="Why Salesforce generative AI is the next big thing?" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/07/salesforce-einstein-gpt-why-generative-ai-is-the-next-big-thing/">Salesforce Einstein GPT: Why Generative AI is the next big thing?</Link></h1>
                            <p className="text-lg">Read about how Salesforce Einstien is revolutionizing the way CRM works.</p>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default SalesforceDevelopmentServicesPage;